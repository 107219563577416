import clsx from "clsx";
import {Box, Theme, Typography} from "@material-ui/core";
import {Energy5Link} from "../energy5/atoms";
import {ArrowLinkRightIcon} from 'assets/svgs';
import React from "react";
import { makeStyles } from "@material-ui/styles";
import useI18n from "evcharging/hooks/useI18n";

export const ServiceCard = ({ title, url, bgImage, onClick }) => {
  const classes = useStyles();

  const { t } = useI18n();

  return (
    <Box
      className={clsx(classes.serviceCard)}
      style={{ backgroundImage: `url(${bgImage})` }}
      onClick={onClick}
    >
      <Box width={`100%`} height={'auto'} overflow="hidden">
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>

        <Box className={classes.linkContainer}>
          <Energy5Link text={t('action.explore')} href={url} className={classes.link} />
          <ArrowLinkRightIcon className={classes.linkArrow} />
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    serviceCard: {
      height: 100,
      willChange: 'width, opacity, min-height',
      transition: 'opacity .3s, width .3s, min-height .3s',
      minHeight: 480,
      maxHeight: 480,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      padding: theme.spacing(3, 8),
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        transition: 'opacity .3s, width .3s',
        minHeight: 480,
        maxHeight: 480,
        justifyContent: 'flex-end',
        padding: theme.spacing(18, 6),
        '&:hover': {
          cursor: 'pointer',
        },
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(18, 14),
      },
      "&:hover $linkArrow": {
        transform: 'translateX(30px) scale(1.5)'
      },
    },
    title: {
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        whiteSpace: 'pre-line',
      },
    },
    text: {
      color: theme.palette.common.white,
      whiteSpace: 'pre-wrap',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(8),
      },
    },
    linkContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 14,
      fontSize: 20,
      color: '#F9C106',

    },
    link: {
      fontSize: 20,
      color: '#F9C106',
      background: 'none',
      padding: 0,
      fontWeight: 400,
      '&:hover': {
        background: 'none',
      },
    },
    linkArrow: {
      transition: 'transform .2s'
    }
  };
});
