import React, { FC } from 'react';
import { Energy5Link } from 'components/energy5/atoms/index';
import { RETAIL_COMPARE_ENERGY_RATES_FULL_ROUTE } from 'config/retail/routes';
import { CustomerType } from 'services/orders/types';

interface RetailAppLinkProps {
  text: string;
  id: string;
}

const RetailAppLink: FC<RetailAppLinkProps> = (props) => {
  const { text, id } = props;
  return (
    <Energy5Link
      href={RETAIL_COMPARE_ENERGY_RATES_FULL_ROUTE(CustomerType.COMMERCIAL)}
      text={text}
      id={id}
    />
  );
};

export default RetailAppLink;
