import React, { FC } from 'react';
import { ImageSection } from 'components/energy5/structures';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { exploreEnergy5 } from 'assets/images/home';
import useI18n from "evcharging/hooks/useI18n";

const ExploreEnergySection: FC = () => {
  const { t } = useI18n();

  const classes = useStyles();

  return (
    <ImageSection
      className={classes.root}
      title={t('home.explore-energy5')}
      text={t('home.explore-energy5-text')}
      imageSrc={exploreEnergy5}
      width={705}
      height={642}
      link={{ text: t('nav.contact'), href: '/contact-us' }}
      setCenterText
      setSpaceBetweenLg
      showBlueBgEffect
    />
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(30, 0),
      },
    },
  };
});

export default ExploreEnergySection;
