import React, { FC, ReactNode } from 'react';
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

export interface Energy5ButtonProps extends ButtonProps {
  text: string;
  onClick?: () => void;
  className?: string;
  icon?: ReactNode;
  isPrimary?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
    padding: theme.spacing(2.6, 6.8),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1.8, 8),
    },
  },
  primaryButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#f6c215',
    },
  },
}));

const Energy5IconButton: FC<Energy5ButtonProps> = ({ onClick, text, icon, isPrimary }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      startIcon={icon}
      className={clsx(classes.button, isPrimary && classes.primaryButton)}
    >
      {text}
    </Button>
  );
};

export default Energy5IconButton;
