import React, { FC, forwardRef } from 'react';
import { SvgIconProps } from '@material-ui/core';
import ComparePlansIconSvg from 'assets/svgs/home/ComparePlansSvg.svg';
import SwitchSupplierIconSvg from 'assets/svgs/home/SwitchSuplierSvg.svg';
import PickFromListIconSvg from 'assets/svgs/home/PickFromListSvg.svg';
import SaveOnEnergyIconSvg from 'assets/svgs/home/SaveOnEnergySvg.svg';
import CustomizePlanIconSvg from 'assets/svgs/home/CustomizePlanSvg.svg';

import EliminateTheNeedIconSvg from 'assets/svgs/home/EliminateTheNeedSvg.svg';
import CalculateSavingsIconSvg from 'assets/svgs/home/CalculateSavingsSvg.svg';
import ObtainRecommendationsIconSvg from 'assets/svgs/home/ObtainRecommendationsSvg.svg';
import ScheduleInstallationIconSvg from 'assets/svgs/home/ScheduleInstallationSvg.svg';
import ExploreRebatesIconSvg from 'assets/svgs/home/ExploreRebatesSvg.svg';

import InstallChargingIconSvg from 'assets/svgs/home/InstallChargingSvg.svg';
import EVSoftwareIconSvg from 'assets/svgs/home/EVSoftwareSvg.svg';
import BenefitsForCustomersIconSvg from 'assets/svgs/home/BenefitsForCustomersSvg.svg';
import TimeSavingIconSvg from 'assets/svgs/home/TimeSavingSvg.svg';
import EcoFriendlyIconSvg from 'assets/svgs/home/EcoFriendlySvg.svg';

export const ComparePlansIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ComparePlansIconSvg ref={ref} {...props} />
));

export const SwitchSupplierIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SwitchSupplierIconSvg ref={ref} {...props} />
));

export const PickFromListIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <PickFromListIconSvg ref={ref} {...props} />
));

export const SaveOnEnergyIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <SaveOnEnergyIconSvg ref={ref} {...props} />
));

export const CustomizePlanIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CustomizePlanIconSvg ref={ref} {...props} />
));

export const EliminateTheNeedIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <EliminateTheNeedIconSvg ref={ref} {...props} />
));

export const CalculateSavingsIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <CalculateSavingsIconSvg ref={ref} {...props} />
));

export const ObtainRecommendationsIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ObtainRecommendationsIconSvg ref={ref} {...props} />
));

export const ScheduleInstallationIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ScheduleInstallationIconSvg ref={ref} {...props} />
));

export const ExploreRebatesIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ExploreRebatesIconSvg ref={ref} {...props} />
));

export const InstallChargingIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <InstallChargingIconSvg ref={ref} {...props} />
));

export const EVSoftwareIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <EVSoftwareIconSvg ref={ref} {...props} />
));

export const BenefitsForCustomersIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <BenefitsForCustomersIconSvg ref={ref} {...props} />
));

export const TimeSavingIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <TimeSavingIconSvg ref={ref} {...props} />
));

export const EcoFriendlyIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <EcoFriendlyIconSvg ref={ref} {...props} />
));
