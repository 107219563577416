import React, {FC, useMemo} from 'react';
import { makeStyles } from '@material-ui/styles';
import { alpha, Box, Container, Grid, Theme, Typography } from '@material-ui/core';
import LedSliderArrows from 'components/common/structures/Slider';
import Slider from 'react-slick';
import {
  sliderEvChargingImage,
  sliderLedServicesImage,
  sliderRetailImage,
} from 'assets/images/home';
import useI18n from "evcharging/hooks/useI18n";

const SliderSection: FC = () => {
  const { t } = useI18n();

  const classes = useStyles();
  const sliderRef = React.createRef<Slider>();

  const slidesInfo = useMemo<Array<{ title: string; text: string; image: string }>>(() => [
    {
      title: 'home.header.retail-energy-caption-full',
      text: 'home.slider.slide-retail-energy-text',
      image: sliderRetailImage,
    },
    {
      title: 'home.header.led-caption-full',
      text: 'home.slider.slide-led-text',
      image: sliderLedServicesImage,
    },
    {
      title: 'home.header.ev-charging-caption-full',
      text: 'home.slider.slide-ev-charging-text',
      image: sliderEvChargingImage,
    },
  ], [])

  const nextSlide = () => {
    try {
      sliderRef.current.slickNext();
    } catch (e) {
      console.log(e);
    }
  };

  const prevSlide = () => {
    try {
      sliderRef.current.slickPrev();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Box className={classes.upperSection}>
        <Container maxWidth={false} fixed>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h3" color="textSecondary">
                {t('home.why-energy5')}
              </Typography>
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                {slidesInfo.length > 1 && (
                  <Box className={classes.sliderController}>
                    <LedSliderArrows
                      className={classes.arrowsWrapperImage}
                      onNext={nextSlide}
                      onPrev={prevSlide}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box>
        <Container maxWidth={false} fixed>
          <Box className={classes.sliderWrapper}>
            <Slider
              dots={false}
              infinite
              speed={500}
              arrows={false}
              slidesToShow={2}
              slidesToScroll={1}
              responsive={[
                {
                  breakpoint: 960,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              ref={sliderRef}
            >
              {slidesInfo.map((slide, index) => (
                <Box key={index}>
                  <div
                    className={classes.sliderImage}
                    style={{ backgroundImage: `url(${slide.image})` }}
                  >
                    <Box
                      className={classes.sliderContent}
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                    >
                      <Box mb={2}>
                        <Typography variant="h4">{t(slide.title)}</Typography>
                      </Box>
                      <Typography color="textSecondary">{t(slide.text)}</Typography>
                    </Box>
                  </div>
                </Box>
              ))}
            </Slider>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  upperSection: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(10, 0, 24),
    marginBottom: theme.spacing(-24),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(14, 0, 30),
      marginBottom: theme.spacing(-30),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(20, 0, 34),
      marginBottom: theme.spacing(-34),
    },
  },
  sliderWrapper: {
    '& .slick-slide': {
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 3),
      },
    },
    '& .slick-track': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& .slick-list': {
      padding: theme.spacing(6, 0),
      margin: theme.spacing(0, -2),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, -3),
      },
    },
  },
  sliderController: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  arrowsWrapperImage: {
    flex: '0 0 auto',
    '& button': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.5),
      '&:not(:last-child)': {
        marginRight: theme.spacing(4),
      },
      '&:hover': {
        backgroundColor: theme.palette.customColors.light,
      },
      '& svg path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  sliderImage: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    borderRadius: 10,
    color: theme.palette.common.white,
    minHeight: 380,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'auto',
      justifyContent: 'flex-end',
    },
  },
  sliderContent: {
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.8)',
    padding: theme.spacing(10, 5),
    borderRadius: theme.spacing(0, 0, 2, 2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
      borderRadius: theme.spacing(0, 2, 2, 0),
      minHeight: 400,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '45%',
    },
  },
}));

export default SliderSection;
