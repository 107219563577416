import { Button, ButtonProps, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

interface LedSubmitButtonProps extends ButtonProps {
  children: ReactNode | string;
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    '& circle': {
      stroke: theme.palette.common.white,
    },
  },
}));

const SubmitButton: FC<LedSubmitButtonProps> = ({ children, loading, ...buttonProps }) => {
  const ref = useRef(null);
  const [minWidth, setMinWidth] = useState(null);
  useEffect(() => {
    setMinWidth(ref.current.clientWidth);
  }, []);
  const classes = useStyles();
  return (
    <Button
      style={{ minWidth }}
      ref={ref}
      {...buttonProps}
      variant="contained"
      color="primary"
      type="submit"
    >
      {loading ? (
        <CircularProgress size={28} color="secondary" className={classes.spinner} />
      ) : (
        children
      )}
    </Button>
  );
};

export default SubmitButton;
