import { action, observable } from 'mobx';
import { isShortZipCodeOnType } from 'utils/form/asserts';
import { FormValidators } from 'utils/form/types';
import { isRequired, isShortZipCode } from 'utils/form/validators';
import { validateValues } from 'utils/form';

interface Form {
  zipCode: string;
}

const initialForm = {
  zipCode: '',
};

class InputZipCodeStore {
  form: Form = observable(initialForm);

  get isValid(): boolean {
    return validateValues<Form>(this.validators, this.form)[0];
  }

  clearForm = action(() => {
    this.form.zipCode = initialForm.zipCode;
  });

  onChangeZipCode = action((zipCode: string) => {
    if (zipCode.length > 5 || !isShortZipCodeOnType(zipCode)) {
      return;
    }
    this.form.zipCode = zipCode;
  });

  protected get validators(): FormValidators<Form> {
    return {
      zipCode: [isRequired(), isShortZipCode()],
    };
  }
}

export default InputZipCodeStore;
