import React, {FC, useMemo} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme, Typography } from '@material-ui/core';
import { Energy5Link } from 'components/energy5/atoms';
import { Energy5LinkProps } from 'components/energy5/atoms/Energy5Link';
import clsx from 'clsx';
import { EV_CHARGING_URL, LIGHTING_RETROFIT_URL, RETAIL_URL } from 'config/seo/urls';
import { useRouter } from 'next/router';
import {
  headerEvChargingImage,
  headerLedServicesImage,
  headerRetailEnergyImage,
} from 'assets/images/home';
import useI18n from "evcharging/hooks/useI18n";

type LinkProps = Omit<Energy5LinkProps, 'isAnchor' | 'className'>;

interface VerticalSliderItem {
  imgSrc: string;
  title: string;
  fullTitle: string;
  subTitle: string;
  fullSubTitle: string;
  text: string;
  zipInput?: boolean;
  link?: LinkProps;
}

const SLIDES = [
  {
    key: 'retail-energy',
    imgSrc: headerRetailEnergyImage,
    zipInput: true,
    url: RETAIL_URL
  },
  {
    key: 'ev-charging',
    imgSrc: headerEvChargingImage,
    url: EV_CHARGING_URL
  },
  {
    key: 'led',
    imgSrc: headerLedServicesImage,
    url: LIGHTING_RETROFIT_URL
  }
]

const getVerticalSlides = (t): Array<VerticalSliderItem> => SLIDES.map(slide => {
  const { key, imgSrc, zipInput, url} = slide

  return {
    zipInput,
    imgSrc,
    subTitle: t(`home.header.${key}-caption`),
    fullSubTitle: t(`home.header.${key}-caption-full`),
    title: t(`home.header.${key}-title`),
    fullTitle: t(`home.header.${key}-title-full`),
    text: t(`home.header.${key}-text`),
    link: {
      text: `home.header.${key}-link`,
      href: url,
    },
  }
})

const HeaderSection: FC = () => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useI18n();

  const verticalSlides = useMemo(() => getVerticalSlides(t), [t])

  const initialStateSlides = useMemo(() => {
    const defaultSlideWidth = 100 / verticalSlides.length;
    return  Array(verticalSlides.length).fill(defaultSlideWidth);
  }, [verticalSlides])

  const changeWidthSlide = (index: number) => {
    return router.push(verticalSlides[index].link.href);
  };

  return (
    <Box className={classes.root}>
      {initialStateSlides.map((slide, index) => (
        <Box
          className={clsx(classes.verticalSlide)}
          style={{ backgroundImage: `url(${verticalSlides[index].imgSrc})` }}
          onClick={() => changeWidthSlide(index)}
          key={index}
        >
          <Box className={classes.slide}>
            <Typography variant="body2" color="primary">
              <strong>{verticalSlides[index].fullSubTitle}</strong>
            </Typography>
            <Typography variant="h2" className={classes.title}>
              {verticalSlides[index].fullTitle}
            </Typography>
            <Typography variant="h3" component="h3" className={classes.text}>
              {verticalSlides[index].text}
            </Typography>
            {verticalSlides[index].link && (
              <Energy5Link
                text={verticalSlides[index].link?.text}
                href={verticalSlides[index].link?.href}
                onClick={verticalSlides[index].link?.onClick}
              />
            )}
          </Box>
          <Box width={`100%`} height={'auto'} overflow="hidden">
            <Typography variant="body2" color="primary">
              <strong>{verticalSlides[index].subTitle}</strong>
            </Typography>
            <Typography variant="h2" className={classes.title}>
              {verticalSlides[index].title}
            </Typography>
            <Energy5Link text={t('action.explore')} href={verticalSlides[index].link.href} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    slide: {
      width: '100%',
      height: 0,
      overflow: 'hidden',
      transition: 'opacity .2s',
      willChange: 'opacity',
      transitionDelay: '.2s',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% / 3)',
      },
    },
    verticalSlide: {
      height: 100,
      willChange: 'width, opacity, min-height',
      transition: 'opacity .3s, width .3s, min-height .3s',
      minHeight: 270,
      maxHeight: 540,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      padding: theme.spacing(3, 8),
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        transition: 'opacity .3s, width .3s',
        minHeight: 760,
        maxHeight: 760,
        justifyContent: 'flex-end',
        padding: theme.spacing(18, 6),
        '&:hover': {
          cursor: 'pointer',
        },
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(18, 14),
      },
    },
    title: {
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        whiteSpace: 'pre-line',
      },
    },
    text: {
      color: theme.palette.common.white,
      whiteSpace: 'pre-wrap',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(8),
      },
    },
  };
});

export default HeaderSection;
