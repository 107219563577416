import React, { FC } from 'react';
import { alpha, makeStyles, Theme } from '@material-ui/core';
import { Link as ScrollLink } from 'react-scroll';
import clsx from 'clsx';
import { PageLink } from "components/common/atoms";

export interface Energy5LinkProps {
  text: string;
  href: string;
  onClick?: () => void;
  className?: string;
  isAnchor?: boolean;
  id?: string;
}

export const useStylesEnergy5Link = makeStyles((theme: Theme) => ({
  linkButton: {
    display: 'block',
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 6,
    maxWidth: 'max-content',
    border: '1px solid transparent',
    padding: theme.spacing(3, 6),
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
    willChange: 'box-shadow',
    transition: 'box-shadow 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.primary.main, 0.9),
      boxShadow: '0px 4px 19px rgba(0, 0, 0, 0.1)',
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2.4, 6),
      fontSize: 18,
    },
  },
}));

const Energy5Link: FC<Energy5LinkProps> = (props) => {
  const { href, text, isAnchor, className, id } = props;
  const classes = useStylesEnergy5Link();
  const linkProps = id ? { id } : {};

  return (
    <>
      {isAnchor ? (
        <ScrollLink
          to={href}
          smooth={true}
          duration={500}
          offset={-80}
          className={clsx(classes.linkButton, className)}
        >
          {text}
        </ScrollLink>
      ) : (
        <PageLink href={href} className={clsx(classes.linkButton, className)} {...linkProps}>
          {text}
        </PageLink>
      )}
    </>
  );
};

export default Energy5Link;
