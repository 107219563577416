import React, { FC } from 'react';
import { alpha, Box, IconButton, makeStyles, Theme } from '@material-ui/core';
import { ArrowLedSvgIconPos } from 'assets/svgs/led';

interface LedSliderArrowsProps {
  className?: string;
  onPrev: () => void;
  onNext: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonArrow: {
    width: 32,
    height: 32,
    padding: 0,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
    },
    '& svg path': {
      fill: theme.palette.common.white,
    },
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      width: 42,
      height: 42,
    },
  },
}));

const LedSliderArrows: FC<LedSliderArrowsProps> = ({ className, onNext, onPrev }) => {
  const classes = useStyles();

  return (
    <Box className={className}>
      <IconButton className={classes.buttonArrow} onClick={onPrev} aria-label="Left">
        <ArrowLedSvgIconPos position="left" />
      </IconButton>
      <IconButton className={classes.buttonArrow} onClick={onNext} aria-label="Right">
        <ArrowLedSvgIconPos position="right" />
      </IconButton>
    </Box>
  );
};

export default LedSliderArrows;
