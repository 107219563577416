import React, {FC, useMemo} from 'react';
import { Box, Container } from '@material-ui/core';
import { InlineListCards } from 'components/energy5/structures';
import { InlineListCardItem } from 'components/energy5/structures/InlineListCards';
import {
  InstallChargingIcon,
  EVSoftwareIcon,
  BenefitsForCustomersIcon,
  TimeSavingIcon,
  EcoFriendlyIcon,
} from 'assets/svgs/home';
import useI18n from "evcharging/hooks/useI18n";

const EvListCards: FC = () => {
  const { t } = useI18n();

  const cards = useMemo<Array<InlineListCardItem>>(() => [
    {
      text: t('home.ev-charging.obtain'),
      image: <InstallChargingIcon />,
    },
    {
      text: t('home.ev-charging.take'),
      image: <EVSoftwareIcon />,
    },
    {
      text: t('home.ev-charging.find'),
      image: <BenefitsForCustomersIcon />,
    },
    {
      text: t('home.ev-charging.reduce'),
      image: <TimeSavingIcon />,
    },
    {
      text: t('home.ev-charging.create'),
      image: <EcoFriendlyIcon />,
    },
  ], [t]);

  return (
    <Box>
      <Container maxWidth={false} fixed>
        <InlineListCards data={cards} />
      </Container>
    </Box>
  );
};

export default EvListCards;
