import { TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { RETAIL_COMPARE_ENERGY_RATES_ROUTE } from 'config/retail/routes';
import { triggerRetailEnterZipCodeEvent, triggerSelectServiceEvent } from 'config/seo/events';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import InputZipCodeStore from 'stores/common/InputZipCodeStore';
import { preventNonNumberChar } from 'utils/form/helpers';
import SubmitButton from 'components/common/atoms/SubmitButton';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#abafb5',
      },
      '&:hover fieldset': {
        borderColor: '#abafb5',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#abafb5',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) => {
  return {
    areaInput: {
      width: 300,
      height: 60,
      background: '#ffffff',
      '& input': {
        '-moz-appearance': 'textfield',
        '-webkit-appearance': 'none',
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        padding: '20.5px 14px',
        '& ::placeholder': {
          color: '#93979b',
          fontSize: 18,
          fontWeight: 600,
          LineHeight: 16,
        },
      },
      borderRadius: 6,
      transition: 'all 0.4s',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    button: {
      marginLeft: theme.spacing(2.5),
      height: 60,
      padding: theme.spacing(3),
      width: 70,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 6,
      boxShadow: 'none',
      color: theme.palette.common.white,
      fontSize: 16,
      fontWeight: 800,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    form: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
    },
  };
});

const inputZipCodeStore = new InputZipCodeStore();

const ZipCodeInputField: FC = observer(() => {
  const classes = useStyles();
  const { push } = useRouter();
  const { onChangeZipCode, isValid, form, clearForm } = inputZipCodeStore;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const zipCodeInput = document.getElementById('zipCodeInput') as HTMLInputElement;
    onChangeZipCode(zipCodeInput.value.substr(0, 5));
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    onChangeZipCode(e.target.value);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isValid) {
      setIsLoading(true);
      triggerRetailEnterZipCodeEvent(form.zipCode);
      await push(RETAIL_COMPARE_ENERGY_RATES_ROUTE(form.zipCode));
      setIsLoading(false);
      clearForm();
    }
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <CssTextField
        id="zipCodeInput"
        placeholder="Enter ZIP Code..."
        variant="outlined"
        className={classes.areaInput}
        value={form.zipCode}
        onChange={onChange}
        onKeyPress={preventNonNumberChar}
      />
      <SubmitButton loading={isLoading} className={classes.button} disabled={!isValid}>
        Go
      </SubmitButton>
    </form>
  );
});

export default ZipCodeInputField;
