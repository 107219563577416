import React from "react";
import { NextPage } from "next";
import { Page } from "containers/common/sections/layout";
import {
  EvListCards,
  // EvParallaxSection,
  // ExploreEnergySection,
  // HeaderSection,
  HeaderSectionNew,
  // LedListCards,
  // LedParallaxSection,
  // RetailListCards,
  // RetailParallaxSection,
  // SliderSection
} from "containers/common/sections/home";
import { createGetServerSideProps, StaticPageProps } from "evcharging/app";
import { I18nPage } from "i18n/types/pages.enum";
import { getServices } from "evcharging/services/cmsClient";
import { E5Service } from "evcharging/services/types";

const HomePage: NextPage<StaticPageProps & { services: E5Service[] }> = (props) => {
  const { page, services} = props

  return (
    <Page title={page.meta_title} description={page.meta_description} locales={page.locales}>
      {/* OLD HOME PAGE */}
      {/*{ENV.IS_PRODUCTION ? (*/}
      {/*  <>*/}
      {/*    <HeaderSection />*/}
      {/*    <SliderSection />*/}
      {/*    <ExploreEnergySection />*/}
      {/*    <RetailParallaxSection />*/}
      {/*    <RetailListCards />*/}
      {/*    <LedParallaxSection />*/}
      {/*    <LedListCards />*/}
      {/*    <EvParallaxSection />*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  <>*/}
          <HeaderSectionNew services={services} />
      {/*  </>*/}
      {/*)}*/}
      <EvListCards />
    </Page>
  );
};

export const getServerSideProps = createGetServerSideProps(undefined, async (_, locale) => {
  return {
    services: await getServices(locale)
  }
}, [I18nPage.Home]);

export default HomePage;
