import React, { FC } from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { useStylesEnergy5Link } from 'components/energy5/atoms/Energy5Link';
import clsx from 'clsx';

export interface Energy5ButtonProps {
  text: string;
  onClick: () => void;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: theme.spacing(2.6, 6.8),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1.8, 8),
    },
  },
}));

const Energy5Button: FC<Energy5ButtonProps> = ({ onClick, text, className }) => {
  const linkClasses = useStylesEnergy5Link();
  const classes = useStyles();

  return (
    <Button onClick={onClick} className={clsx(linkClasses.linkButton, classes.button, className)}>
      {text}
    </Button>
  );
};

export default Energy5Button;
