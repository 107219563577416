import React, { FC } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface BgAfterProps {
  position?: 'left';
  height?: number;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  bgAfterRelative: {
    position: 'relative',
  },
  bgAfter: {
    minWidth: 290,
    height: ({ height }: Partial<BgAfterProps>) => `calc(${height ? height : 100}% + 71px)`,
    position: 'absolute',
    right: ({ position }: Partial<BgAfterProps>) => (position ? 'inherit' : -10),
    left: ({ position }: Partial<BgAfterProps>) => (!position ? 'inherit' : -10),
    top: -35,
    backgroundColor: theme.palette.customColors.light,
    borderRadius: theme.spacing(4),
    zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      width: '65%',
      right: ({ position }: Partial<BgAfterProps>) => (position ? 'inherit' : -36),
      left: ({ position }: Partial<BgAfterProps>) => (!position ? 'inherit' : -36),
    },
    [theme.breakpoints.up('md')]: {
      right: ({ position }: Partial<BgAfterProps>) => (position ? 'inherit' : -40),
      left: ({ position }: Partial<BgAfterProps>) => (!position ? 'inherit' : -40),
      height: ({ height }: Partial<BgAfterProps>) => `calc(${height ? height : 100}% + 100px)`,
      width: 300,
      top: -50,
    },
    [theme.breakpoints.up('lg')]: {
      top: -72,
      width: '65%',
      height: ({ height }: Partial<BgAfterProps>) => `calc(${height ? height : 100}% + 144px)`,
      right: ({ position }: Partial<BgAfterProps>) => (position ? 'inherit' : '-17.3%'),
      left: ({ position }: Partial<BgAfterProps>) => (!position ? 'inherit' : '-17.3%'),
    },
  },
}));

const Energy5BgAfter: FC<BgAfterProps> = ({ className, height, position, children }) => {
  const classes = useStyles({ height, position });

  return (
    <Box className={clsx(classes.bgAfterRelative, className)}>
      {children}
      <Box className={classes.bgAfter} />
    </Box>
  );
};

export default Energy5BgAfter;
