import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme } from '@material-ui/core';
import { useRouter } from 'next/router';
import useI18n from 'evcharging/hooks/useI18n';
import { ServiceCard } from 'components/services/ServiceCard';
import { assetUrl } from 'evcharging/services/cmsClient';
import { E5Service } from 'evcharging/services/types';
import { STATIC_SERVICES } from 'config/e5app';

const HeaderSectionNew: FC<{ services: E5Service[] }> = ({ services }) => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useI18n();

  const redirect = (url: string) => {
    return router.push(url);
  };

  return (
    <Box className={classes.root}>
      {STATIC_SERVICES.map((service) => (
        <ServiceCard
          key={service.key}
          url={service.url}
          bgImage={service.imgSrc}
          title={t(service.title)}
          onClick={() => redirect(service.url)}
        />
      ))}
      {services.map((service) => (
        <ServiceCard
          key={service.name}
          url={`/services/${service.key}`}
          bgImage={assetUrl(service.thumbnail)}
          title={service.name}
          onClick={() => redirect(`/services/${service.key}`)}
        />
      ))}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },
    }
  };
});

export default HeaderSectionNew;
